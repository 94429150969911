import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { FromCostProto, ToCostProto } from 'shared/mappers/cost.mapper'

import * as commonQuery from 'proto/common/query_pb'
import * as currencyv1 from 'proto/currency/v1/currency_pb'
import { CostServicePromiseClient } from 'proto/invoicing/v1/cost_grpc_web_pb'
import {
  CostFilter,
  CostSorting,
  ListCostsRequest,
  ListCostsResponse,
  SetBookingCostsRequest,
  SetBookingCostsResponse,
  SetCostStatusRequest,
  SetCostStatusResponse,
  Cost as pbCost,
} from 'proto/invoicing/v1/cost_pb'

import { Actions as CurrencyActions } from '../../store/currency/actions'
import { getBranchDefaultCurrency } from '../../store/iam/branch/reducer'
import {
  Actions,
  LIST_REQ,
  SET_BOOKING_COSTS_REQ,
  SET_COST_STATUS_REQ,
} from '../../store/invoicing/cost/actions'
import { Actions as NotificationActions } from '../../store/notification/actions'
import { RootState } from '../../store/reducer'

import { authMetadata } from '../../helpers/auth'
import { stringToTimeStamp } from 'shared/helpers/datetime.helper'

import { Cost } from 'types/cost'

import { GRPCClients } from '../clients'

export function* list(
  client: CostServicePromiseClient,
  action: ReturnType<typeof Actions.listCostsReq>,
) {
  try {
    const { page } = action.payload
    const req = new ListCostsRequest()

    if (page?.pagination?.limit !== 0 || page.pagination.skip !== 0) {
      const pagination = new commonQuery.Pagination()
      pagination.setLimit(page?.pagination?.limit || 25)
      pagination.setSkip(page?.pagination?.skip || 0)
      req.setPagination(pagination)
    }

    if (page.filter) {
      const filter = new CostFilter()
      if (page.filter.bookingReference && page.filter.bookingReference.length > 0) {
        filter.setBookingRefList(page.filter.bookingReference)
      }
      if (page.filter.status && page.filter.status.length > 0) {
        filter.setStatusList(page.filter.status as unknown as pbCost.Status[])
      }
      if (page.filter.type && page.filter.type.length > 0) {
        filter.setTypeList(page.filter.type as unknown as pbCost.Type[])
      }
      if (page.filter.organization && page.filter.organization.length > 0) {
        filter.setOrganizationIdList(page.filter.organization)
      }
      if (page.filter.branchId && page.filter.branchId.length > 0) {
        filter.setBranchIdList(page.filter.branchId)
      }
      if (page.filter.search && page.filter.search !== '') {
        filter.setSearch(page.filter.search)
      }
      if (page.filter.shipmentRef && page.filter.shipmentRef != '') {
        filter.setShipmentRef(page.filter.shipmentRef)
      }
      req.setFilter(filter)
    }

    if (page.sorting) {
      const sort = new CostSorting()

      sort.setField(page.sorting.field as unknown as CostSorting.Field)
      sort.setOrdering(page.sorting.ordering as unknown as CostSorting.Ordering)
      req.setSorting(sort)
    }
    const resp: ListCostsResponse = yield call([client, client.listCosts], req, authMetadata())

    yield setCostCurrencies(resp.getCostsList().map(FromCostProto))

    yield put(
      Actions.listCostsResp(resp.getTotalCount(), resp.getCostsList().map(FromCostProto), page),
    )
  } catch (err: any) {
    yield put(Actions.listCostsErr(err))
  }
}

function* setCostCurrencies(costs: Cost[]) {
  if (costs.length > 0) {
    const defaultBranchCurrency: string = yield select((state: RootState) =>
      getBranchDefaultCurrency(state),
    )
    const convList: currencyv1.ConvertCurrency[] = []
    costs.forEach((c) => {
      if (c.actualAmount !== 0 && c.currency.length > 0 && c.currency !== defaultBranchCurrency) {
        const convObj = new currencyv1.ConvertCurrency()
        convObj.setBookingRef(c.bookingRef)
        convObj.setContextId(c.costId)
        convObj.setCurrencyFrom(c.currency)
        convObj.setKeyCurrency(defaultBranchCurrency)
        convObj.setCurrencyTo(defaultBranchCurrency)
        convObj.setAmount(c.actualAmount)
        convObj.setType(currencyv1.ConvertCurrency.Type.COST)
        convObj.setLookupDate(stringToTimeStamp(c.convertedCurrency))
        convList.push(convObj)
      }
    })

    if (convList.length > 0) {
      yield put(CurrencyActions.convertCurrencyReq(convList, currencyv1.ConvertCurrency.Type.COST))
    }
  }
}

export function* setBookingCosts(
  client: CostServicePromiseClient,
  action: ReturnType<typeof Actions.setBookingCostsReq>,
) {
  try {
    const { costs } = action.payload
    const req = new SetBookingCostsRequest()
    const bookingRef = costs[0].bookingRef
    req.setCostsList(costs.map(ToCostProto))
    const resp: SetBookingCostsResponse = yield call(
      [client, client.setBookingCosts],
      req,
      authMetadata(),
    )

    if (resp.getCostsList().length > 0) {
      yield put(
        NotificationActions.send({
          key: `booking-${bookingRef}`,
          kind: 'success',
          message: 'Costs Saved',
          description: 'Costs were saved successfully',
          dismissAfter: 4500,
        }),
      )
    } else {
      throw new Error('Response contained no booking ref')
    }
    yield put(Actions.setBookingCostsResp(resp.getCostsList().map(FromCostProto)))
  } catch (err: any) {
    yield put(Actions.setBookingCostsErr(err))
  }
}

export function* setCostStatus(
  client: CostServicePromiseClient,
  action: ReturnType<typeof Actions.setCostStatusReq>,
) {
  try {
    const { costId, status, comment } = action.payload

    const req = new SetCostStatusRequest()
    req.setCostId(costId)
    req.setStatus(status as unknown as pbCost.Status)
    req.setComment(comment)

    const resp: SetCostStatusResponse = yield call(
      [client, client.setCostStatus],
      req,
      authMetadata(),
    )
    const cost = resp.getCost()
    if (cost) {
      yield put(
        NotificationActions.send({
          key: `cost-${cost.getCostId()}`,
          kind: 'success',
          message: 'Cost Updated',
          description: `Cost status was changed successfully`,
          dismissAfter: 4500,
        }),
      )
    } else {
      throw new Error('Response contained no cost')
    }
    yield put(Actions.setCostStatusResp(FromCostProto(cost)))
  } catch (err: any) {
    yield put(Actions.setCostStatusErr(err))
  }
}

export default function* sagas(clients: GRPCClients) {
  yield takeLatest(LIST_REQ, list, clients.cost)
  yield takeEvery(SET_BOOKING_COSTS_REQ, setBookingCosts, clients.cost)
  yield takeEvery(SET_COST_STATUS_REQ, setCostStatus, clients.cost)
}
